import React, { useEffect, useState, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import {
  Navbar,
  Nav,
  Button,
  Container,
  Modal,
  Badge,
  NavDropdown,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logo-new2.png";
import logo1 from "../assets/image1.jpeg";
import esLogo from "../assets/esLogo.png";
import { register, logout } from "../actions/userActions";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useScroll } from "./ScrollContext";
import useIsHomePage from "./useIsHomePage";
import { Form, InputGroup } from "react-bootstrap";
import Message from "./Message";
import { login } from "../actions/userActions";
import FormContainer from "./FormContainer";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import SpinnerIcon from "./spinner";
import ReactGA from "react-ga4";
import toast from "react-simple-toasts";
import { USER_REGISTER_RESET } from "../constants/userConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import { useMediaQuery, useMediaQueries } from "@react-hook/media-query";
import { ModalContext } from "./ModalContext";
import { SearchContext } from "./SearchContext";

const optionEstateagent = [
  { value: "estate-agent", label: "Estate Agent/Broker/Realtor" },
  { value: "not-estate-agent", label: "Not Estate Agent" },
];

const optionPropertyType = [
  { value: "Property Type", label: "Property" },
  { value: "Apartment", label: "Apartment" },
  { value: "Villa", label: "Villa" },
  { value: "Townhouse", label: "Townhouse" },
  { value: "Penthouse", label: "Penthouse" },
  { value: "Compound", label: "Compound" },
  { value: "Duplex", label: "Duplex" },
  { value: "Studio", label: "Studio" },
];

const optionRentBuy = [
  { value: "Rent", label: "Rent" },
  { value: "Buy", label: "Buy" },
  { value: "Commercial-Rent", label: "Commercial Rent" },
  { value: "Commercial-Buy", label: "Commercial Buy" },
];

const optionBedrooms = [
  { value: "Bedrooms", label: "Beds" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7+" },
];

const defaultPropertyType = optionPropertyType[0];
const defaultRentBuy = optionRentBuy[0];
const defaultBedrooms = optionBedrooms[0];

const SubNavbar = ({ isFixed, activeLink, setActiveLink }) => {
  const { searchedResults, finalPropertyList } = useContext(SearchContext);
  const navigate = useNavigate();

  // console.log("RESULTS", searchedResults, finalPropertyList);

  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [searchQuery, setLocation] = useState("");
  const [PropertyType, setPropertyType] = useState("");
  const [RentBuy, setRentBuy] = useState("");
  const [Bedrooms, setBedrooms] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 1024);
    };

    // Call the function initially to set the state based on the current window size
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.key === "p")) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //SEARCH SUBMIT HANDLER
  const submitSearchHandler = async (e) => {
    e.preventDefault();

    try {
      const propertyType = PropertyType.value;
      const rentBuy = RentBuy.value !== undefined ? RentBuy.value : "Rent";
      const bedrooms = Bedrooms.value;

      if (
        propertyType === undefined ||
        rentBuy === undefined ||
        bedrooms === undefined
      ) {
        toast("Please select an option in all search fields", {
          time: 6000,
          position: "top-center",
          clickable: true,
          clickClosable: true,
          className: "custom-toast",
          render: (message) => (
            <div
              style={{
                paddingRight: 4,
                paddingLeft: 4,
                borderRadius: 30,
                fontSize: 18,
                textAlign: "center",
                justifyContent: "center",
                fontWeight: "600",
                marginTop: "10rem",
                marginBottom: "4rem",
                color: "#ffffff",
                padding: 4,
                backgroundColor: "red",
                zIndex: 1,
                opacity: 1,
              }}
            >
              Please select an option in all search fields
            </div>
          ),
        });
      } else {
        if (activeLink === "/home") {
          navigate(
            `/home?searchQuery=${searchQuery}&propertyType=${propertyType}&bedrooms=${bedrooms}&rentBuy=${rentBuy}&check=true`
          );
        } else if (activeLink === "/admin/propertyListnew") {
          navigate(
            `/admin/propertyListnew?searchQuery=${searchQuery}&propertyType=${propertyType}&bedrooms=${bedrooms}&rentBuy=${rentBuy}&check=true`
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRentBuy = (selectedOption) => {
    setRentBuy(selectedOption);
  };

  const handlePropertyType = (selectedOption) => {
    setPropertyType(selectedOption);
  };

  const handleBedrooms = (selectedOption) => {
    setBedrooms(selectedOption);
  };

  const className = ` sub-navbar ${isFixed ? "fixed" : ""} `;

  return (
    <Nav
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: "#ffffff",
        borderBottom: "1px solid",
        borderBottomColor: "#e0e0eb",
        paddingBottom: isMobile ? 20 : 0,
        paddingTop: 10,
      }}
    >
      <Container className='justify-content-center' style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            backgroundColor: "#ffffff",
            width: "100%",
          }}
        >
          <Form
            onSubmit={submitSearchHandler}
            style={{
              width: "100%",
              display: !isMobile && "flex",
              paddingTop: 4,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Form.Group
              controlId='location'
              style={{
                paddingLeft: 2,
                marginBottom: isMobile ? "0.5rem" : "1rem",
                width: isMobile ? "100%" : "25%",
                borderRadius: 30,
                marginRight: !isMobile && 10,
              }}
            >
              <Form.Control
                className='border shadow-sm custom-textarea custom-placeholder'
                as='textarea'
                rows={1}
                style={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                  borderRadius: 30,
                  boxShadow: "none",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "center",
                  borderWidth: "0.3px",
                  color: "#000000", // Text color
                  "::placeholder": { color: "#000000" }, // Placeholder color
                }}
                maxLength={120}
                type='location'
                placeholder='Location'
                value={searchQuery}
                onChange={(e) => setLocation(e.target.value)}
              />
            </Form.Group>

            <Select
              options={optionPropertyType}
              onChange={handlePropertyType}
              autoFocus={false}
              defaultValue={defaultPropertyType}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 2000,
                }),
                container: (provided) => ({
                  ...provided,
                  width: isMobile ? "100%" : "18%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: isMobile ? "0.5rem" : "1rem",
                  marginBottom: isMobile ? "0.5rem" : "2rem",
                  marginRight: isMobile ? "0.5rem" : "1rem",
                  textAlign: "center",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "10px",
                  borderWidth: "0.3px",
                  fontSize: 16,
                  fontWeight: 500,
                  width: "100%",
                }),
              }}
            />

            <Select
              options={optionRentBuy}
              onChange={handleRentBuy}
              autoFocus={false}
              defaultValue={defaultRentBuy}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 2000,
                }),
                container: (provided) => ({
                  ...provided,
                  width: isMobile ? "100%" : "18%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: isMobile ? "0.5rem" : "1rem",
                  marginBottom: isMobile ? "0.5rem" : "2rem",
                  marginRight: isMobile ? "0.5rem" : "1rem",
                  textAlign: "center",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "10px",
                  borderWidth: "0.3px",
                  fontSize: 16,
                  fontWeight: 500,
                }),
              }}
            />

            <Select
              options={optionBedrooms}
              onChange={handleBedrooms}
              autoFocus={false}
              defaultValue={defaultBedrooms}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 2000,
                }),
                container: (provided) => ({
                  ...provided,
                  width: isMobile ? "100%" : "18%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: isMobile ? "0.5rem" : "1rem",
                  marginBottom: isMobile ? "0.5rem" : "2rem",
                  marginRight: isMobile ? "0.5rem" : "1rem",
                  textAlign: "center",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "10px",
                  borderWidth: "0.3px",
                  fontSize: 16,
                  fontWeight: 500,
                }),
              }}
            />
            {/* </div> */}
            <Col
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                width: isMobile ? "100%" : "10%",
                marginTop: isTablet ? 1 : 10,
              }}
            >
              <Button
                className='border shadow-sm'
                type='submit'
                style={{
                  width: "100%",
                  backgroundColor: "red",
                  color: "#ffffff",
                  fontSize: 14,
                  fontWeight: "600",
                  margin: 5,
                  marginRight: 0,
                  marginTop: isTablet || isMobile ? 0 : -35,
                  marginBottom: isTablet ? 5 : isMobile ? -5 : -10,
                  padding: 5,
                  // marginBottom: 2,
                  borderRadius: 30,
                  borderColor: "red",
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
                size='sm'
              >
                Search for wanted Properties
              </Button>
              {searchedResults.length !== 0 &&
                finalPropertyList.length !== 0 && (
                  <p
                    style={{
                      color: "red",
                      fontSize: 14,
                      fontWeight: "600",
                      textAlign: "center",
                      marginTop: isTablet ? 0 : 2,
                      marginBottom: -10,
                    }}
                  >
                    {finalPropertyList.length}{" "}
                    {finalPropertyList.length > 1 ? "results" : "result"}
                  </p>
                )}
            </Col>
          </Form>
        </div>
      </Container>
    </Nav>
  );
};

const Header = () => {
  const {
    showLoginModal: showLoggedInModal,
    openLoginModal: openLoggedInModal,
    closeLoginModal: closeLoggedInModal,
  } = useContext(ModalContext);

  // console.log('SHOW', closeLoggedInModal)

  const navigate = useNavigate();

  const { buyRef, rentRef, scrollToSection } = useScroll();

  const isHomePage = useIsHomePage();

  const handleNavClick = (ref, section) => {
    if (isHomePage) {
      scrollToSection(ref);
    } else {
      navigate("/");
      setTimeout(() => {
        scrollToSection(ref);
      }, 100);
    }
  };

  const dispatch = useDispatch();

  const { keyword: keywordi } = useParams();

  const myParam = useLocation().search;

  const keywords = new URLSearchParams(myParam).get("keyword");

  const keyword = keywords !== null ? keywords : keywordi;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [emailValidated, setEmailValidated] = useState(false);
  const [passwordValidated, setPasswordValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [activeLink, setActiveLink] = useState(window.location.pathname);

  // console.log("ACTIVE LINK", activeLink);

  const matches = useMediaQuery("only screen and (max-width: 430px)");

  const options = [
    { value: "male", label: "male" },
    { value: "female", label: "female" },
  ];

  // console.log('VALIDATED',email, password )

  const [yourName, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [genderValue, setSelected] = useState(null);
  const [dateOfBirth, setStartDate] = useState(new Date());
  const [isEstateAgent, setEastateAgent] = useState("");
  const [officeRegistrationNumber, setOfficeRegistrationNumber] = useState("");
  const [brokerRegistrationNumber, setBrokerRegistrationNumber] = useState("");

  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const gender =
    genderValue === undefined || genderValue === "" || genderValue === null
      ? null
      : genderValue.value;

  //DATE OF BIRTH FILTER BELOW 18 AGE GROUP FUNCTION
  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  const date = new Date();

  const minDate = subtractYears(date, 18);

  //FILTER CONDITION PASSED INTO THE DATE PICKER
  const afterCurrentDayFilter = (date) => {
    return date < minDate;
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const propertyList = useSelector((state) => state.propertyList);
  const { contents, page, pages } = propertyList;

  const [showLoginModal, setShowLoginModal] = useState(false);

  const logoutHandler = () => {
    dispatch(logout());

    navigate("/");

    window.localStorage.clear();
  };

  //LOGIN HANDLER
  const submitHandler = async (e) => {
    e.preventDefault();

    window.localStorage.clear(); //clear all localstorage

    // Perform form validation
    if (email.trim() === "") {
      setEmailValidated(true);
      return;
    }

    if (password.trim() === "") {
      setPasswordValidated(true);
      return;
    }

    if (email !== "" && password !== "") {
      dispatch(login(email, password));

      setEmail("");

      setPassword("");
    } else {
      toast("Invalid email or password", {
        time: 5000,
        position: "top-center",
        clickable: true,
        clickClosable: true,
        className: "custom-toast",
        render: (message) => (
          <div style={{ marginTop: "10rem" }}>
            <Message variant='danger'>Invalid email or password</Message>
          </div>
        ),
      });
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleLoginButtonClick = () => {
    setShowLoginModal(true); // Open modal when "Login" button is clicked
  };

  const handleCloseLoginModal = useCallback(() => {
    if (showLoggedInModal) {
      closeLoggedInModal();
    } else {
      setShowLoginModal(false);
    }
  }, [showLoggedInModal, closeLoggedInModal]);

  const handleRegisterButtonClick = () => {
    setShowRegisterModal(true);
  };

  const handleCloseRegisterModal = () => {
    setShowRegisterModal(false);
  };

  //REGISTER HANDLER
  const submitRegisterHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      if (
        moment(dateOfBirth).format("YYYY-MM-DD") ===
        moment(new Date()).format("YYYY-MM-DD")
      ) {
        setMessage("Please enter a valid date of birth");
      } else {
        if (email !== "" && password !== "") {
          dispatch(
            register(
              yourName,
              companyName,
              email,
              password,
              dateOfBirth,
              isEstateAgent,
              officeRegistrationNumber,
              brokerRegistrationNumber
            )
          );

          setMessage("");

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          setMessage("Invalid email or password");
        }

        if (userInfo) {
          setEmail("");

          setPassword("");

          setName("");

          setCompanyName("");

          setConfirmPassword("");

          setStartDate(new Date());

          setEastateAgent("");

          setOfficeRegistrationNumber("");

          setBrokerRegistrationNumber("");
        }
      }
    }
  };

  const handleChangeGender = (selectedOption) => {
    setSelected(selectedOption);
  };

  const handleEstateagent = (selectedOption) => {
    setEastateAgent(selectedOption);
    // console.log(`Option selected:`, selectedOption);
  };

  const handleThisLoginButtonClick = () => {
    if (userInfo) {
      // Navigate to contact us if user is logged in
      navigate("/admin/contactUs");
    } else {
      // Open the login modal if user is not logged in
      handleLoginButtonClick();
    }
  };

  useEffect(() => {
    if (userInfo) {
      handleCloseLoginModal();
    }
  }, [userInfo, handleCloseLoginModal]);

  useEffect(() => {
    const handleScroll = () => {
      const subNavbar = document.querySelector(".sub-navbar");
      if (subNavbar) {
        const offsetTop = subNavbar.offsetTop;
        setIsFixed(window.scrollY - 20 >= offsetTop);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Update activeLink when the URL changes
  const location = useLocation();
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <>
      <Navbar
        // activeLink={activeLink}
        // setActiveLink={setActiveLink}
        expand='sm'
        className='border shadow-sm border border-1'
        style={{ padding: 10, paddingLeft: 0, marginTop: 0, color: "#232F3E" }}
      >
        <Container>
          <Navbar.Brand
            as={Link}
            to='/'
            onClick={() => setActiveLink("/")}
            // href='/'

            style={{ marginTop: -15, marginBottom: -15 }}
          >
            <img
              src={esLogo}
              width='70'
              height='70'
              style={{
                marginRight: isMobile ? 0 : 20,
                marginLeft: isMobile ? 0 : 20,
              }}
              className='d-inline-block align-top'
              alt='Logo'
            />
          </Navbar.Brand>
          <p
            style={{
              color: "#ffffff",
              backgroundColor: "red",
              borderRadius: 30,
              padding: isMobile ? 4 : 5,
              marginRight: isMobile ? 4 : 10,
              marginTop: 8,
            }}
          >
            Beta
          </p>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link
                onClick={() => handleNavClick(buyRef)}
                style={{ color: "#232F3E", fontWeight: "500" }}
              >
                Buy
              </Nav.Link>
              <Nav.Link
                onClick={() => handleNavClick(rentRef)}
                style={{ color: "#232F3E", fontWeight: "500" }}
              >
                Rent
              </Nav.Link>
              <Nav.Link
                as={Link}
                to='/home'
                active={activeLink === "/home"}
                onClick={() => setActiveLink("/home")}
                style={{ color: "#232F3E", fontWeight: "500" }}
              >
                Wanted-Properties
              </Nav.Link>
              {userInfo ? (
                <Nav.Link
                  href='/admin/createPropertyRequest'
                  style={{ color: "#232F3E", fontWeight: "500" }}
                >
                  Property-Request
                </Nav.Link>
              ) : (
                <Nav.Link
                  as={Link}
                  onClick={handleThisLoginButtonClick}
                  active={activeLink === "/admin/createPropertyRequest"}
                  style={{ color: "#232F3E", fontWeight: "500" }}
                >
                  Property-Request
                </Nav.Link>
              )}

              {userInfo && userInfo.isanEstateAgent === "not-estate-agent" ? (
                <Nav.Link
                  as={Link}
                  to='/admin/propertyListnew'
                  active={activeLink === "/admin/propertyListnew"}
                  onClick={() => setActiveLink("/admin/propertyListnew")}
                  style={{ color: "#232F3E", fontWeight: "500" }}
                >
                  Property-Inbox
                </Nav.Link>
              ) : userInfo &&
                userInfo.isanEstateAgent === "estate-agent" ? null : (
                <Nav.Link
                  as={Link}
                  onClick={handleThisLoginButtonClick}
                  active={activeLink === "/admin/propertyListnew"}
                  style={{ color: "#232F3E", fontWeight: "500" }}
                >
                  Property-Inbox
                </Nav.Link>
              )}

              <Nav.Link
                href='/aboutUs'
                style={{ color: "#232F3E", fontWeight: "500" }}
              >
                About
              </Nav.Link>
              <Nav.Link
                onClick={handleThisLoginButtonClick}
                style={{ color: "#232F3E", fontWeight: "500" }}
              >
                Contact
              </Nav.Link>
              {userInfo && (
                <Nav.Link
                  href='/admin/Profile'
                  style={{ color: "#232F3E", fontWeight: "500" }}
                >
                  Profile
                </Nav.Link>
              )}

              {/* Agents Section */}

              {(userInfo && userInfo.isanEstateAgent === "estate-agent") ||
              (userInfo && userInfo.isAdmin) ? (
                <NavDropdown
                  title={
                    <span style={{ color: "#232F3E", fontWeight: "500" }}>
                      Agents
                    </span>
                  }
                >
                  <NavDropdown.Item
                    href='/admin/propertyListnew'
                    // active={activeLink === "/admin/propertyListnew"}
                    onClick={() => setActiveLink("/admin/propertyListnew")}
                    style={{
                      textTransform: "lowercase",
                      color: "#232F3E",
                      fontWeight: "500",
                    }}
                  >
                    Property-Inbox
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href='/admin/agentsAddPropertyBank'
                    // active={activeLink === "/admin/agentsAddPropertyBank"}
                    onClick={() =>
                      setActiveLink("/admin/agentsAddPropertyBank")
                    }
                    style={{
                      color: "#232F3E",
                      fontWeight: "500",
                      textTransform: "lowercase",
                    }}
                  >
                    Add Listings
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link
                  as={Link}
                  onClick={handleThisLoginButtonClick}
                  // active={activeLink === "/admin/propertyListnew"}
                  style={{ color: "#232F3E", fontWeight: "500" }}
                >
                  Agents
                </Nav.Link>
              )}

              {/* Admin Section */}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown
                  title={
                    <span style={{ color: "#232F3E", fontWeight: "500" }}>
                      Admin
                    </span>
                  }
                >
                  <NavDropdown.Item
                    href='/admin/CommentsTable'
                    style={{
                      textTransform: "lowercase",
                      color: "#232F3E",
                      fontWeight: "500",
                    }}
                  >
                    Comments Table
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item
                    href='/admin/TicketTable'
                    style={{
                      color: "#232F3E",
                      fontWeight: "500",
                      textTransform: "lowercase",
                    }}
                  >
                    Ticket Table
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item
                    href='/admin/healthStatus'
                    style={{
                      color: "#232F3E",
                      fontWeight: "500",
                      textTransform: "lowercase",
                    }}
                  >
                    ES Health Status
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
            {userInfo ? (
              <div style={{ alignSelf: "flex-end" }}>
                <Button
                  style={{
                    marginLeft: isMobile ? 0 : 10,
                    alignSelf: "flex-end",
                    marginRight: 10,

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    borderColor: "red",

                    borderRadius: 30,
                    backgroundColor: "#ffffff",
                    fontWeight: "bold",
                    fontSize: 14,
                    color: "red",
                  }}
                  variant='primary'
                  onClick={logoutHandler}
                >
                  Log out
                </Button>
              </div>
            ) : (
              <div style={{ alignSelf: "flex-end" }}>
                <Button
                  style={{
                    marginLeft: isMobile ? 0 : 10,
                    alignSelf: "flex-end",
                    marginRight: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor: "red",
                    height: "2.3em",
                    borderRadius: 30,
                    backgroundColor: "#ffffff",
                    fontWeight: "bold",
                    fontSize: 14,
                    color: "red",
                  }}
                  variant='primary'
                  // onClick={ () => navigate("/login")}
                  onClick={handleLoginButtonClick}
                >
                  Log in
                </Button>
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* SUBNAV BAR */}
      {(activeLink === "/home" || activeLink === "/admin/propertyListnew") && (
        <SubNavbar
          isFixed={isFixed}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
        />
      )}

      {/* Register Modal */}
      <Modal
        show={showLoggedInModal === true ? showLoggedInModal : showLoginModal}
        onHide={handleCloseLoginModal}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: 18, color: "red", fontWeight: "bold" }}
          >
            {" "}
            Welcome to Estate Sands
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 20 }}>
          {error && <Message variant='danger'>{error}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='email' style={{ marginBottom: 10 }}>
              <Form.Label style={{ fontWeight: 500 }}>Email</Form.Label>
              <Form.Control
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                isInvalid={emailValidated && email.trim() === ""}
                className='border shadow-lg'
              />
              <Form.Control.Feedback type='invalid'>
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='password' style={{ marginBottom: 10 }}>
              <Form.Label style={{ fontWeight: 500 }}>Password</Form.Label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  isInvalid={passwordValidated && password.trim() === ""}
                  className='border shadow-lg'
                />
                <span
                  className='flex justify-around items-center'
                  onClick={() => {
                    setShowPassword(!showPassword);
                    handleToggle();
                  }}
                  style={{
                    justifyContent: "center",
                    paddingTop: 10,
                    padding: 5,
                    cursor: "pointer",
                  }}
                >
                  <Icon className='absolute ml-1' icon={icon} size={24} />
                </span>
              </div>
              <Form.Control.Feedback type='invalid'>
                Please provide a valid password.
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              type='submit'
              className='border shadow-lg'
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0.5em",
                borderColor: "red",
                width: "6em",
                height: "2.3em",
                borderRadius: 30,
                backgroundColor: "#ffffff",
                fontWeight: "bold",
                fontSize: 14,
                color: "red",
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              Log In
            </Button>
          </Form>

          <Row className='py-3'>
            <Col>
              <Link
                to='/password-reset'
                onClick={() => {
                  handleCloseLoginModal();
                }}
                style={{
                  color: "black",
                  fontWeight: 500,
                  textDecoration: "none",
                }}
              >
                Forgot Password?
              </Link>
            </Col>
          </Row>

          <Row className='py-3'>
            <Col>
              <span
                style={{
                  color: "black",
                  fontWeight: 500,
                  textDecoration: "none",
                }}
              >
                New to Estate Sands?
              </span>
              <span
                onClick={() => {
                  handleCloseLoginModal();
                  handleRegisterButtonClick();
                }}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 16,
                  textDecoration: "none",
                }}
              >
                {""} Register
              </span>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Login Modal */}
      <Modal show={showRegisterModal} onHide={handleCloseRegisterModal}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: 18, color: "red", fontWeight: "bold" }}
          >
            Welcome to Estate Sands
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <Message variant='danger'>{message}</Message>}
          <Form onSubmit={submitRegisterHandler}>
            <Form.Group controlId='yourName' style={{ marginBottom: 10 }}>
              <Form.Label style={{ fontWeight: 500 }}>Name</Form.Label>
              <Form.Control
                style={{ boxShadow: "none", fontSize: "16px" }}
                type='yourName'
                value={yourName}
                onChange={(e) => setName(e.target.value)}
                className='border shadow-lg'
              />
            </Form.Group>

            <Form.Group controlId='companyName' style={{ marginBottom: 10 }}>
              <Form.Label style={{ fontWeight: 500 }}>Company Name</Form.Label>
              <Form.Control
                style={{ boxShadow: "none", fontSize: "16px" }}
                type='companyName'
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className='border shadow-lg'
              />
            </Form.Group>

            <Form.Group controlId='email' style={{ marginBottom: 10 }}>
              <Form.Label style={{ fontWeight: 500 }}>Email</Form.Label>
              <Form.Control
                style={{ boxShadow: "none", fontSize: "16px" }}
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='border shadow-lg'
              />
            </Form.Group>

            <Form.Group controlId='password' style={{ marginBottom: 10 }}>
              <Form.Label style={{ fontWeight: 500 }}>Password</Form.Label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Control
                  style={{ boxShadow: "none", fontSize: "16px" }}
                  type={type}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='border shadow-lg'
                />
                <span
                  className='flex justify-around items-center'
                  onClick={handleToggle}
                  style={{
                    justifyContent: "center",
                    paddingTop: 10,
                    marginLeft: 4,
                  }}
                >
                  <Icon className='absolute ml-1' icon={icon} size={24} />
                </span>
              </div>
            </Form.Group>

            <Form.Group
              controlId='confirmPassword'
              style={{ marginBottom: 20 }}
            >
              <Form.Label style={{ fontWeight: 500 }}>
                Confirm Password
              </Form.Label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Control
                  style={{ boxShadow: "none", fontSize: "16px" }}
                  type={type}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className='border shadow-lg'
                />
                <span
                  className='flex justify-around items-center'
                  onClick={handleToggle}
                  style={{
                    justifyContent: "center",
                    paddingTop: 10,
                    marginLeft: 4,
                  }}
                >
                  <Icon className='absolute ml-1' icon={icon} size={24} />
                </span>
              </div>
            </Form.Group>

            <Form.Group controlId='DateOfBirth' style={{ marginBottom: 10 }}>
              <Form.Label style={{ fontWeight: 500, marginBottom: 10 }}>
                When is your birthday?
              </Form.Label>
              <div style={{ width: "100%" }}>
                <DatePicker
                  style={{ boxShadow: "none", fontSize: "16px" }}
                  selected={dateOfBirth}
                  onChange={(date) => setStartDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  filterDate={afterCurrentDayFilter}
                  className='border shadow-lg'
                />
              </div>
              <p style={{ marginTop: 10, fontSize: 14, fontWeight: 500 }}>
                *Your birthday won't be shown publicly.
              </p>
            </Form.Group>

            <p style={{ marginTop: 20, fontWeight: 500 }}>
              Are you registering as an Estate Agent/Broker/Realtor?
            </p>
            <div
              style={{
                width: "100%",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <Select
                options={optionEstateagent}
                onChange={handleEstateagent}
                autoFocus={false}
                className='border shadow-lg'
              />
            </div>

            <div style={{ margin: 20, marginLeft: 0, fontWeight: 500 }}>
              If you are an Estate Agent/Broker/Realtor please fill in the
              fields below.
            </div>

            <Form.Group
              controlId='officeRegistrationNumber'
              style={{ marginBottom: 10 }}
            >
              <Form.Label style={{ fontWeight: 500 }}>
                RERA Office Registration Number (ORN)
              </Form.Label>
              <Form.Control
                style={{ boxShadow: "none", fontSize: "16px" }}
                type='officeRegistrationNumber'
                value={officeRegistrationNumber}
                onChange={(e) => setOfficeRegistrationNumber(e.target.value)}
                className='border shadow-lg'
              />
            </Form.Group>

            <Form.Group
              controlId='brokerRegistrationNumber'
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <Form.Label style={{ fontWeight: 500 }}>
                RERA Broker Registration Number (BRN)
              </Form.Label>
              <Form.Control
                style={{ boxShadow: "none", fontSize: "16px" }}
                type='brokerRegistrationNumber'
                value={brokerRegistrationNumber}
                onChange={(e) => setBrokerRegistrationNumber(e.target.value)}
                className='border shadow-lg'
              />
            </Form.Group>

            <Button
              type='submit'
              className='border shadow-lg'
              // onClick={handleCloseRegisterModal}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0.5em",
                borderColor: "red",
                width: "6em",
                height: "2.3em",
                borderRadius: 30,
                backgroundColor: "#ffffff",
                fontWeight: "bold",
                fontSize: 14,
                color: "red",
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              Register
            </Button>
          </Form>

          <div style={{ margin: "1rem", fontWeight: 500 }}>
            By registering on Estate Sands portal, you hereby consent to the{" "}
            <Link
              to='/terms'
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: 16,
                marginRight: "1rem",
                textDecoration: "none",
              }}
            >
              {" "}
              Terms & Conditions{" "}
            </Link>{" "}
            and{" "}
            <Link
              to='/privacy'
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: 16,
                marginLeft: "1rem",
                textDecoration: "none",
              }}
            >
              {" "}
              Privacy Policy{" "}
            </Link>
          </div>

          <Row className='py-3'>
            <Col style={{ fontWeight: 500 }}>
              Have an account?{" "}
              <span
                onClick={() => {
                  handleLoginButtonClick();
                  handleCloseRegisterModal();
                }}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 16,
                  textDecoration: "none",
                }}
              >
                Log In
              </span>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
