import {
  COMMENT_ADD_REQUEST,
  COMMENT_ADD_SUCCESS,
  COMMENT_ADD_FAIL,
  COMMENT_LIST_ADD_REQUEST,
  COMMENT_LIST_ADD_SUCCESS,
  COMMENT_LIST_ADD_FAIL,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_FAIL,
  COMMENTS_MY_LIST_REQUEST,
  COMMENTS_MY_LIST_SUCCESS,
  COMMENTS_MY_LIST_FAIL,
} from "../constants/commentConstants";

export const commentAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_ADD_REQUEST:
      return { loading: true };
    case COMMENT_ADD_SUCCESS:
      return { loading: false, success: true, comment: action.payload };
    case COMMENT_ADD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const commentListReducer = (state = { comments: [] }, action) => {
  switch (action.type) {
    case COMMENT_LIST_ADD_REQUEST:
      return {
        loading: true,
        comments: [],
      };
    case COMMENT_LIST_ADD_SUCCESS:
      return {
        loading: false,
        comments: action.payload.comments,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case COMMENT_LIST_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const commentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_DELETE_REQUEST:
      return { loading: true };
    case COMMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case COMMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialState = {
  comments: [],
  loading: false,
  error: null,
};

export const myCommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_MY_LIST_REQUEST:
      return {
        ...state,

        loading: true,

        comments: [],
      };

    case COMMENTS_MY_LIST_SUCCESS:
      return {
        ...state,

        loading: false,

        comments: action.payload.comments,

        pages: action.payload.pages,

        page: action.payload.page,

        userInfoNode: action.payload.userInfoNode,
      };

    case COMMENTS_MY_LIST_FAIL:
      return {
        ...state,

        loading: false,

        error: action.payload,
      };

    default:
      return state;
  }
};
