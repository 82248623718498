import React from "react";
import { createRoot, ReactDOM } from "react-dom/client";
import "./index.css";
// import './bootstrap.min.flaty.css'
// import './bootstrap.min.superhero.css'
// import './bootstrap.min.cerulean.css'
// import './bootstrap.min.zephyr.css'
// import './bootstrap.min.css'
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ScrollProvider } from "./components/ScrollContext";
import { ModalProvider } from "./components/ModalContext";
import { SearchProvider } from "./components/SearchContext";
import { HelmetProvider } from "react-helmet-async";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// Replace 'YOUR_STRIPE_PUBLISHABLE_KEY' with your actual Stripe publishable key
const stripePromise = loadStripe(stripeKey);

const domNode = document.getElementById("root");

const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <SearchProvider>
          <ScrollProvider>
            <ModalProvider>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </ModalProvider>
          </ScrollProvider>
        </SearchProvider>
      </Elements>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
