import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import promise from "redux-promise";
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
// import { autoMergeLevel2 } from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import secureLocalStorage from "react-secure-storage";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from "./rootReducers";

const persistConfig = {
  key: "root",
  storage: secureLocalStorage,
  whitelist: ["userInfo", "users", "user"],
  stateReconciler: autoMergeLevel2,
};

persistConfig.debug = true;

const persistedReducer = persistReducer(persistConfig, rootReducer);

// RETREIVING USER FROM STORAGE
const userInfoFromStorage = secureLocalStorage.getItem("userInfo")
  ? JSON.parse(secureLocalStorage.getItem("userInfo"))
  : null;

//INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleware = [thunk];

// Check if we are in development mode
const enhancer =
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware);

const store = createStore(persistedReducer, initialState, enhancer);

export default store;
