import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  GET_MAIL_REQUEST,
  GET_MAIL_SUCCESS,
  GET_MAIL_FAIL,
  PROFILE_PICTURE_REQUEST,
  PROFILE_PICTURE_SUCCESS,
  PROFILE_PICTURE_FAIL,
  PROFILE_PICTURE_RESET,
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_STATUS_FAIL,
  DELETE_STATUS_REQUEST,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAIL,
  MAIL_DELETE_REQUEST,
  MAIL_DELETE_SUCCESS,
  MAIL_DELETE_FAIL,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return { userInfo: {} };
    default:
      return state;
  }
};

export const mailReducer = (state = { mail: [] }, action) => {
  switch (action.type) {
    case GET_MAIL_REQUEST:
      return {
        loading: true,
        mail: [],
      };

    case GET_MAIL_SUCCESS:
      return {
        loading: false,
        mail: action.payload.mail,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case GET_MAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const mailDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_DELETE_REQUEST:
      return { loading: true };
    case MAIL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MAIL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const profilePictureReducer = (state = { photos: {} }, action) => {
  switch (action.type) {
    case PROFILE_PICTURE_REQUEST:
      return { ...state, loading: true };
    case PROFILE_PICTURE_SUCCESS:
      return { loading: false, photos: action.payload };
    case PROFILE_PICTURE_FAIL:
      return { loading: false, error: action.payload };
    case PROFILE_PICTURE_RESET:
      return { photos: {} };
    default:
      return state;
  }
};

export const subscriptionStatusReducer = (
  state = { subscription: {} },
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_STATUS_REQUEST:
      return { loading: true };
    case SUBSCRIPTION_STATUS_SUCCESS:
      return { loading: false, success: true, subscription: action.payload };
    case SUBSCRIPTION_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDeleteStatusReducer = (state = { userDelete: {} }, action) => {
  switch (action.type) {
    case DELETE_STATUS_REQUEST:
      return { loading: true };
    case DELETE_STATUS_SUCCESS:
      return { loading: false, success: true, userDelete: action.payload };
    case DELETE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
