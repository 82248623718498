export const COMMENT_ADD_REQUEST = "COMMENT_ADD_REQUEST";
export const COMMENT_ADD_SUCCESS = "COMMENT_ADD_SUCCESS";
export const COMMENT_ADD_FAIL = "COMMENT_ADD_FAIL";

export const COMMENT_LIST_ADD_REQUEST = "COMMENT_LIST_ADD_REQUEST";
export const COMMENT_LIST_ADD_SUCCESS = "COMMENT_LIST_ADD_SUCCESS";
export const COMMENT_LIST_ADD_FAIL = "COMMENT_LIST_ADD_FAIL";

export const COMMENT_DELETE_REQUEST = "COMMENT_DELETE_REQUEST";
export const COMMENT_DELETE_SUCCESS = "COMMENT_DELETE_SUCCESS";
export const COMMENT_DELETE_FAIL = "COMMENT_DELETE_FAIL";

export const COMMENTS_MY_LIST_REQUEST = "COMMENTS_MY_LIST_REQUEST";
export const COMMENTS_MY_LIST_SUCCESS = "COMMENTS_MY_LIST_SUCCESS";
export const COMMENTS_MY_LIST_FAIL = "COMMENTS_MY_LIST_FAIL";
