import axios from "axios";
import toast from "react-simple-toasts";
import { GET_COUNTS, UPDATE_COUNTS } from "../constants/countConstants";

// reducers.js
const initialState = {
  count: 0,
  isLimitReached: false,
};

export const countListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTS:
    case UPDATE_COUNTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
