import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Image,
  Form,
} from "react-bootstrap";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import "./FAQ.css";

const FAQ = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const faqData = [
    {
      question: "How do I create an account?",
      answer:
        "To create an account, click on the register button on the top right and fill in the required details.",
    },
    {
      question:
        "How do I add a property request for a rental property or property to buy?",
      answer:
        "Once you have an account, go to property-request page. Fill in a few details of the property you want and submit. Verified estate agents/brokers/realtors will send you verified matching properties to your Property Inbox.",
    },
    {
      question:
        "How can I send a listing to a client as an estate agent or broker or realtor?",
      answer:
        "Once you have an account, on Wanted-Properties page choose which property request you want to send a listing to and this will take you to a page where you can send your listing to the client. Fill in the listing details and send to the client.",
    },
    {
      question: "What are the benefits of using Estate Sands?",
      answer:
        "Estate Sands offers a comprehensive platform for connecting renters, buyers, and estate agents with ease and reliability.",
    },
    {
      question: "How do I contact an estate agent?",
      answer:
        "You can contact an agent directly from the property inbox page by opening the listing and clicking on the prefered contact option.",
    },
    {
      question: "What payment methods are accepted?",
      answer: "We accept all major credit cards.",
    },
  ];

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.key === "p")) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container style={{ padding: isMobile ? 20 : 40 }}>
      <Card
        className='border shadow-sm faq'
        style={{ borderBottom: "none", marginTop: 40, marginBottom: 40 }}
      >
        <Card.Body>
          {/* <div className="faq" style={{marginTop: 40, marginBottom: 40 }}> */}
          <h3
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              fontSize: isMobile ? 18 : 26,
            }}
          >
            Frequently Asked Questions
          </h3>
          {faqData.map((item, i) => (
            <div key={i} className='faq-item'>
              <div className='faq-question' onClick={() => toggle(i)}>
                {item.question}
                <span className='icon'>{selected === i ? "-" : "+"}</span>
              </div>
              <div
                className={selected === i ? "faq-answer show" : "faq-answer"}
              >
                {item.answer}
              </div>
            </div>
          ))}
          {/* </div> */}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FAQ;
