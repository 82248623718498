import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  GET_MAIL_REQUEST,
  GET_MAIL_SUCCESS,
  GET_MAIL_FAIL,
  PROFILE_PICTURE_REQUEST,
  PROFILE_PICTURE_SUCCESS,
  PROFILE_PICTURE_FAIL,
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_STATUS_FAIL,
  DELETE_STATUS_REQUEST,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAIL,
  MAIL_DELETE_REQUEST,
  MAIL_DELETE_SUCCESS,
  MAIL_DELETE_FAIL,
} from "../constants/userConstants";
import axios from "axios";
import toast from "react-simple-toasts";
import { URL } from "../App";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

// const URL = 'http://localhost:4000'

export const login = (email, password) => async (dispatch) => {
  //   console.log('URL',  URL)

  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // console.log('LOGIN DATA', email, password )

    const { data } = await axios.post(
      `${URL}/api/users/login`,
      { email, password },
      config
    );

    // console.log('LOGIN DATA 2', data )

    const {
      _id,
      yourName,
      companyName,
      isAdmin,
      isSeller,
      token,
      location,
      bio,
      website,
      createdAt,
      isanEstateAgent,
      officeRegistrationNumber,
      brokerRegistrationNumber,
      subscriptionStatus,
    } = data;

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    //  console.log('LOGIN DATA ', JSON.stringify(_id, yourName, companyName, isAdmin, isSeller, token, location, bio, website, createdAt, blockedContentUser, following, followers).length)

    secureLocalStorage.setItem(
      "userInfo",
      JSON.stringify({
        isanEstateAgent,
        officeRegistrationNumber,
        brokerRegistrationNumber,
        _id,
        subscriptionStatus,
        email,
        yourName,
        companyName,
        isAdmin,
        isSeller,
        token,
        location,
        bio,
        website,
        createdAt,
      })
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? // error.response.data.message
            "Invalid email or password"
          : // : error.message
            "Invalid email or password",
    });
  }
};

export const register =
  (
    yourName,
    companyName,
    email,
    password,
    dateOfBirth,
    isEstateAgent,
    officeRegistrationNumber,
    brokerRegistrationNumber
  ) =>
  async (dispatch) => {
    //   console.log('URL',  URL)
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // console.log('DOB',  isEstateAgent.value)

      const isanEstateAgent = isEstateAgent.value;

      const { data } = await axios.post(
        `${URL}/api/users/register`,
        {
          yourName,
          companyName,
          email,
          password,
          dateOfBirth,
          isanEstateAgent,
          officeRegistrationNumber,
          brokerRegistrationNumber,
        },
        config
      );

      // console.log('DATA USER', data )

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      secureLocalStorage.setItem("userInfo", JSON.stringify(data));

      toast("Registration successful, welcome to Estate Sands, please login", {
        time: 6000,
        position: "top-center",
        clickable: true,
        clickClosable: true,
        className: "custom-toast",
        render: (message) => (
          <div
            style={{
              paddingRight: 4,
              paddingLeft: 4,
              borderRadius: 30,
              fontSize: 18,
              textAlign: "center",
              justifyContent: "center",
              fontWeight: "400",
              marginTop: "10rem",
              marginBottom: "4rem",
              color: "#ffffff",
              padding: 4,
              backgroundColor: "green",
              zIndex: 1,
              opacity: 1,
            }}
          >
            Registration successful, welcome to Estate Sands
          </div>
        ),
      });

      // setTimeout(function(){

      //     // dispatch(logout())

      //     // window.localStorage.clear();

      // })
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const logout = () => (dispatch) => {
  secureLocalStorage.removeItem("userInfo");
  secureLocalStorage.clear();
  dispatch({ type: USER_LOGOUT });
  // dispatch({type: USER_DETAILS_RESET})
  // dispatch({type: USER_LIST_RESET})
};

// send contact mail
export const registerMail = (mailData) => async (dispatch, getState) => {
  // console.log('MAIL ACTION', mailData)

  try {
    dispatch({
      type: GET_MAIL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`${URL}/api/users/mail`, mailData, config);

    console.log("MAIL", data);

    dispatch({
      type: GET_MAIL_SUCCESS,
      payload: data,
    });

    if (data) {
      toast("Message sent to Estate Sands Support Team", {
        time: 4000,
        position: "top-center",
        clickable: true,
        clickClosable: true,
        className: "custom-toast",
        render: (message) => (
          <div
            style={{
              paddingRight: 4,
              paddingLeft: 4,
              borderRadius: 30,
              fontSize: 18,
              textAlign: "center",
              justifyContent: "center",
              fontWeight: "400",
              marginTop: "10rem",
              marginBottom: "4rem",
              color: "#ffffff",
              padding: 4,
              backgroundColor: "green",
              zIndex: 1,
              opacity: 1,
            }}
          >
            Message sent to Estate Sands Support Team
          </div>
        ),
      });
    }
  } catch (err) {
    dispatch({
      type: GET_MAIL_FAIL,
      payload: err.response.data,
    });
  }
};

export const addThumbnail = (thumbnailData) => async (dispatch, getState) => {
  // console.log('THUMB-NAIL', thumbnailData)

  const { thumbnail_url, id } = thumbnailData;

  try {
    dispatch({
      type: PROFILE_PICTURE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    // console.log('TOKEN', userInfo.token)

    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };

    const thumbnailUrl = { thumbnail_url: thumbnail_url };

    const { data } = await axios.post(
      `${URL}/api/users/profilePicture/${id}`,
      thumbnailUrl,
      config
    );

    // console.log('THUMB-NAIL-DATA', data)

    dispatch({
      type: PROFILE_PICTURE_SUCCESS,
      payload: data,
    });

    toast("Profile picture uploaded ", {
      time: 4000,
      position: "top-center",
      clickable: true,
      clickClosable: true,
      className: "custom-toast",
      render: (message) => (
        <div
          style={{
            paddingRight: 4,
            paddingLeft: 4,
            borderRadius: 30,
            fontSize: 18,
            textAlign: "center",
            justifyContent: "center",
            fontWeight: "400",
            marginTop: "10rem",
            marginBottom: "4rem",
            color: "#ffffff",
            padding: 4,
            backgroundColor: "green",
            zIndex: 1,
            opacity: 1,
          }}
        >
          Profile picture updated
        </div>
      ),
    });
  } catch (error) {
    dispatch({
      type: PROFILE_PICTURE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllProfilePictures = () => async (dispatch, getState) => {
  // console.log('YES YES')

  try {
    dispatch({
      type: PROFILE_PICTURE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        // authorization: `Bearer ${userInfo.token}`
      },
    };

    // console.log('USER PROFILE1')

    const { data } = await axios.get(
      `${URL}/api/users/allProfilePictures`,
      config
    );

    // console.log('USER PROFILE 2', data)

    dispatch({
      type: PROFILE_PICTURE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_PICTURE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllMail =
  (keyword = "", pageNumber = "") =>
  async (dispatch, getState) => {
    // console.log('YES YES')

    try {
      dispatch({
        type: GET_MAIL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${userInfo.token}`,
        },
      };

      // console.log('USER PROFILE1')

      const { data } = await axios.get(
        `${URL}/api/users/mail?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      // console.log('USER PROFILE 2', data)

      dispatch({
        type: GET_MAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//COMMENT DELETE ACTION
export const deleteMail = (id) => async (dispatch, getState) => {
  // console.log('ID', id)

  try {
    dispatch({
      type: MAIL_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };

    // console.log('USERID', userInfo._id)

    const userId = userInfo._id;

    const { data } = await axios.delete(
      `${URL}/api/users/mail/delete/${id}/${userId}`,
      config
    );

    // console.log('MAIL DATA', data)

    dispatch({
      type: MAIL_DELETE_SUCCESS,
    }) &&
      toast(`Comment is successfully deleted`, {
        time: 5000,
        position: "top-center",
        clickable: true,
        clickClosable: true,
        className: "custom-toast",
        render: (message) => (
          <div
            style={{
              paddingRight: 8,
              paddingLeft: 8,
              borderRadius: 30,
              fontSize: 18,
              textAlign: "center",
              justifyContent: "center",
              fontWeight: "400",
              marginTop: "30em",
              marginBottom: "4rem",
              color: "#ffffff",
              padding: 8,
              backgroundColor: "green",
              zIndex: 1,
              opacity: 1,
            }}
          >
            Comment is successfully deleted
          </div>
        ),
      });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      // dispatch(logout())
    }
    dispatch({
      type: MAIL_DELETE_FAIL,
      payload: message,
    });
  }
};

export const changeSubscriptionStatus =
  (id, upgraded) => async (dispatch, getState) => {
    // console.log('Video ID', id, upgraded)

    try {
      dispatch({ type: SUBSCRIPTION_STATUS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const upgradedData = { upgraded: upgraded };

      const url = `${URL}/api/users/subscriptionStatus/${id}`;

      const { data } = await axios({
        method: "put",
        mode: "cors",
        url: url,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${userInfo.token}`,
        },
        data: upgradedData,
      });

      // console.log('PUBLIC ACTION', data)

      dispatch({
        type: SUBSCRIPTION_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      //  console.log('ERROR', error)

      dispatch({
        type: SUBSCRIPTION_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteUser = (id, userStatus) => async (dispatch, getState) => {
  // console.log('Video ID', id, userStatus)

  try {
    dispatch({ type: DELETE_STATUS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const userStatusData = { userStatus: userStatus };

    const url = `${URL}/api/users/deleteStatus/${id}`;

    const { data } = await axios({
      method: "put",
      mode: "cors",
      url: url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
      data: userStatusData,
    });

    // console.log('PUBLIC ACTION', data)

    dispatch({
      type: DELETE_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    //  console.log('ERROR', error)

    dispatch({
      type: DELETE_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
