import { combineReducers } from "redux";
import {
  profilePictureReducer,
  mailReducer,
  userLoginReducer,
  userRegisterReducer,
  subscriptionStatusReducer,
  mailDeleteReducer,
} from "./reducers/userReducers";
import {
  contentVideoToPublicReducer,
  contentDeleteReducer,
  propertyListReducer,
  contentPropertyAddReducer,
  propertyDetailsReducer,
  listingCountReducer,
  myPropertyListReducer,
} from "./reducers/contentReducers.js";

import {
  commentAddReducer,
  commentListReducer,
  commentDeleteReducer,
  myCommentsReducer,
} from "./reducers/commentReducers";
import { countListingReducer } from "./reducers/countReducers";

const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  myPropertyList: myPropertyListReducer,

  countReducer: listingCountReducer,

  propertyList: propertyListReducer,
  propertyAddReducer: contentPropertyAddReducer,
  propertyDetails: propertyDetailsReducer,
  countListing: countListingReducer,
  contentDelete: contentDeleteReducer,
  contentVideoToPublic: contentVideoToPublicReducer,

  mail: mailReducer,
  mailDelete: mailDeleteReducer,

  profilePicture: profilePictureReducer,
  upgradedSubscriptionStatus: subscriptionStatusReducer,

  commentAdd: commentAddReducer,
  commentList: commentListReducer,
  commentDelete: commentDeleteReducer,
  myComments: myCommentsReducer,
});

export default rootReducer;
