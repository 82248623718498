import {
  CONTENT_ADD_PROPERTY_REQUEST,
  CONTENT_ADD_PROPERTY_SUCCESS,
  CONTENT_ADD_PROPERTY_FAIL,
  ALL_PROPERTY_LIST_REQUEST,
  ALL_PROPERTY_LIST_SUCCESS,
  ALL_PROPERTY_LIST_FAIL,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_SUCCESS,
  PROPERTY_DETAILS_FAIL,
  CONTENT_DELETE_REQUEST,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DELETE_FAIL,
  CONTENT_TOPUBLIC_REQUEST,
  CONTENT_TOPUBLIC_SUCCESS,
  CONTENT_TOPUBLIC_FAIL,
  INCREMENT_COUNT,
  MY_PROPERTY_LIST_REQUEST,
  MY_PROPERTY_LIST_SUCCESS,
  MY_PROPERTY_LIST_FAIL,
} from "../constants/contentConstants";

export const contentPropertyAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_ADD_PROPERTY_REQUEST:
      return { loading: true };
    case CONTENT_ADD_PROPERTY_SUCCESS:
      return { loading: false, success: true, content: action.payload };
    case CONTENT_ADD_PROPERTY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const propertyListReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case ALL_PROPERTY_LIST_REQUEST:
      return {
        loading: true,
        contents: [],
      };
    case ALL_PROPERTY_LIST_SUCCESS:
      return {
        loading: false,
        contents: action.payload.contents,
        pages: action.payload.pages,
        page: action.payload.page,
        userInfoNode: action.payload.userInfoNode,
      };
    case ALL_PROPERTY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const myPropertyListReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case MY_PROPERTY_LIST_REQUEST:
      return {
        loading: true,
        contents: [],
      };
    case MY_PROPERTY_LIST_SUCCESS:
      return {
        loading: false,
        contents: action.payload.contents,
        pages: action.payload.pages,
        page: action.payload.page,
        userInfoNode: action.payload.userInfoNode,
      };
    case MY_PROPERTY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const propertyDetailsReducer = (state = { content: {} }, action) => {
  switch (action.type) {
    case PROPERTY_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case PROPERTY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.payload.content,
      };
    case PROPERTY_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const contentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_DELETE_REQUEST:
      return { loading: true };
    case CONTENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CONTENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const contentVideoToPublicReducer = (
  state = { content: {} },
  action
) => {
  switch (action.type) {
    case CONTENT_TOPUBLIC_REQUEST:
      return { loading: true };
    case CONTENT_TOPUBLIC_SUCCESS:
      return { loading: false, success: true, content: action.payload };
    case CONTENT_TOPUBLIC_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// reducers.js
const initialState = {
  listingsCount: { month: new Date().getMonth(), count: 0 },
  isLimitReached: false,
};

export const listingCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_COUNT:
      return {
        ...state,
        listingsCount: {
          ...state.listingsCount,
          count: state.listingsCount.count + 1,
        },
      };
    default:
      return state;
  }
};
