export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST ";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS ";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL ";
export const USER_LOGOUT = "USER_LOGOUT ";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST ";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS ";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL ";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const GET_MAIL_REQUEST = "GET_MAIL_REQUEST";
export const GET_MAIL_SUCCESS = "GET_MAIL_SUCCESS";
export const GET_MAIL_FAIL = "GET_MAIL_FAIL";

export const MAIL_DELETE_REQUEST = "MAIL_DELETE_REQUEST";
export const MAIL_DELETE_SUCCESS = "MAIL_DELETE_SUCCESS";
export const MAIL_DELETE_FAIL = "MAIL_DELETE_FAIL";

export const PROFILE_PICTURE_REQUEST = "PROFILE_PICTURE_REQUEST ";
export const PROFILE_PICTURE_SUCCESS = "PROFILE_PICTURE_SUCCESS ";
export const PROFILE_PICTURE_FAIL = "PROFILE_PICTURE_FAIL ";
export const PROFILE_PICTURE_RESET = "PROFILE_PICTURE_RESET";

export const SUBSCRIPTION_STATUS_REQUEST = "SUBSCRIPTION_STATUS_REQUEST";
export const SUBSCRIPTION_STATUS_SUCCESS = "SUBSCRIPTION_STATUS_SUCCESS";
export const SUBSCRIPTION_STATUS_FAIL = "SUBSCRIPTION_STATUS_FAIL";

export const DELETE_STATUS_REQUEST = "DELETE_STATUS_REQUEST";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";
export const DELETE_STATUS_FAIL = "DELETE_STATUS_FAIL";
