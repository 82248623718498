import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalContext } from "../components/ModalContext";

const Footer = () => {
  const { openLoginModal } = useContext(ModalContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: -20,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleContactClick = (e) => {
    e.preventDefault();
    if (userInfo) {
      navigate("/admin/contactUs");
    } else {
      openLoginModal();
    }
  };

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.key === "p")) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <footer
      className='border shadow-lg border-1'
      style={{ backgroundColor: "#E5E8E8", marginBottom: 5 }}
    >
      <Container fluid>
        <Row className='text-center m-4 mb-2'>
          <Col md>
            <p>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#232F3E",
                  fontWeight: "500",
                  fontSize: 16,
                }}
                to='/aboutUs'
                onClick={scrollToTop}
              >
                About
              </Link>
            </p>
          </Col>
          <Col md>
            <p>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#232F3E",
                  fontWeight: "500",
                  fontSize: 16,
                  color: "#232F3E",
                }}
                onClick={handleContactClick}
              >
                Contact
              </Link>
            </p>
          </Col>
          <Col md>
            <p>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#232F3E",
                  fontWeight: "500",
                  fontSize: 16,
                  color: "#232F3E",
                }}
                to='/faq'
                onClick={scrollToTop}
              >
                FAQ
              </Link>
            </p>
          </Col>
          <Col md>
            <p>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#232F3E",
                  fontWeight: "500",
                  fontSize: 16,
                  color: "#232F3E",
                }}
                to='/privacy'
                onClick={scrollToTop}
              >
                Privacy Policy
              </Link>
            </p>
          </Col>
          <Col md>
            <p>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#232F3E",
                  fontWeight: "500",
                  fontSize: 16,
                  color: "#232F3E",
                }}
                to='/terms'
                onClick={scrollToTop}
              >
                Terms & Conditions
              </Link>
            </p>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col md>
            <p style={{ color: "#232F3E", fontWeight: 500 }}>
              &copy; {new Date().getFullYear()} Estate Sands
            </p>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col md>
            <p style={{ color: "#232F3E", fontWeight: 500 }}>
              All rights reserved. Trademark ™
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
