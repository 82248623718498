import React, { useEffect } from "react";
import { Container, Card, Image } from "react-bootstrap";
import ReactGA from "react-ga4";

const AboutUsPage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "LandingPage",
  });

  const isMobile = window.innerWidth < 768;
  const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.key === "p")) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container style={{ marginTop: 40 }}>
      <Card
        className='border shadow-sm'
        style={{ padding: 40, paddingBottom: 100, marginBottom: 60 }}
        role='region'
        aria-labelledby='about-estate-sands'
      >
        <header style={{ paddingBottom: 10, marginTop: 20 }}>
          <h3
            id='about-estate-sands'
            style={{
              color: "red",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            About Estate Sands
          </h3>
          <p style={{ fontWeight: 500 }}>
            Welcome to Estate Sands, an innovative platform for connecting
            property buyers, tenants with estate agents or brokers. At Estate
            Sands, our goal is to streamline the process of finding your dream
            property, ensuring a seamless and hassle-free experience. We make
            the property search process, buying and renting more efficient and
            user-centric.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <Image
              src='https://res.cloudinary.com/dcjo2dysw/image/upload/e_improve,e_sharpen/v1719751673/Signed%20Listing%20Pictures/graphic.png'
              style={{
                width: isMobile ? "350px" : "500px",
                height: isMobile ? "350px" : "500px",
                objectFit: "cover",
                WebkitUserDrag: "none",
                KhtmlUserDrag: "none",
                MozUserDrag: "none",
                OUserDrag: "none",
                userDrag: "none",
                userSelect: "none",
              }}
              alt='Banner'
              className='banner-image'
              aria-hidden='true'
            />
          </div>
        </header>

        <section aria-labelledby='our-mission'>
          <h3
            id='our-mission'
            style={{
              color: "red",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
              marginTop: 10,
            }}
          >
            Our Mission
          </h3>
          <p style={{ fontWeight: 500 }}>
            At Estate Sands, our mission is to revolutionise the real estate
            industry by providing a seamless and efficient platform that
            connects property seekers with trusted estate agents. We aim to
            empower both buyers/tenants and agents, offering them a
            user-friendly interface and innovative tools to streamline the
            property search and listing process.
          </p>
        </section>

        <section aria-labelledby='what-we-offer'>
          <h3
            id='what-we-offer'
            style={{
              marginTop: 20,
              marginBottom: 20,
              color: "red",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            What We Offer
          </h3>
          <h5
            style={{
              color: "red",
              marginLeft: 20,
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
              marginTop: 20,
            }}
            id='property-seekers'
          >
            Property Seekers:
          </h5>
          <ul
            style={{ marginLeft: 20, fontWeight: 500 }}
            aria-labelledby='property-seekers'
          >
            <li>
              <strong>Efficient Property Search:</strong> Provide a few key
              details about the property you're looking for, such as location,
              price range, and number of bedrooms. Different estate agents will
              then send a curated list of matching properties to your property
              inbox, ensuring you receive the best options that meet your
              criteria.
            </li>
            <li>
              <strong>Personalized Experience:</strong> Enjoy a personalized
              experience by managing your list of sent and received properties
              directly in your property inbox.
            </li>
            <li>
              <strong>Connect with Estate Agents:</strong> Easily get in touch
              with verified estate agents, ask questions, schedule viewings, and
              make informed decisions about your property purchase or rental.
            </li>
          </ul>
          <h5
            style={{
              color: "red",
              marginLeft: 20,
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
              marginTop: 20,
            }}
            id='estate-agents'
          >
            Estate Agents:
          </h5>
          <ul
            style={{ marginLeft: 20, fontWeight: 500 }}
            aria-labelledby='estate-agents'
          >
            <li>
              <strong>
                Attract eager prospects by directly engaging with interested
                leads:
              </strong>{" "}
              Send your listings to verified, eager leads who are actively
              waiting for them. By targeting specific, interested clients, you
              can ensure that your properties reach the right audience,
              increasing the likelihood of a quick and successful sale. These
              leads have already expressed genuine interest in your listings,
              saving you time and effort. With this focused approach, you can
              streamline your sales process and achieve better results. Make the
              most of your marketing efforts by connecting with potential
              buyers/tenants who are ready to act. Our system ensures that your
              listings are delivered to those who are serious about finding
              their next property, maximizing your chances of closing deals
              efficiently.
            </li>
            <li>
              <strong>Effortless Listing Management:</strong> Create and manage
              property listings with ease, update details, add photos, and track
              leads all from one convenient dashboard.
            </li>
            <li>
              <strong>Build Trust and Credibility:</strong> Gain visibility and
              credibility as a trusted estate agent among property seekers,
              leading to more inquiries and successful transactions.
            </li>
          </ul>
        </section>

        <section aria-labelledby='our-team'>
          <h3
            id='our-team'
            style={{
              color: "red",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            Our Team
          </h3>
          <p style={{ fontWeight: 500 }}>
            Estate Sands is powered by a dedicated team of professionals who are
            passionate about revolutionizing the real estate industry. With
            years of experience and expertise in technology and real estate, we
            are committed to delivering an exceptional user experience and
            helping you find or sell your property seamlessly.
          </p>
        </section>

        <footer role='contentinfo'>
          <p style={{ fontWeight: 500 }}>
            Get Started Today! Whether you're looking to buy, rent, or sell a
            property, Estate Sands is here to help you every step of the way.
            Join our growing community today and experience the future of real
            estate with Estate Sands!
          </p>
        </footer>
      </Card>
    </Container>
  );
};

export default AboutUsPage;
