import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Link,
} from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SpinnerIcon from "./components/spinner";
import AboutUs from "./screens/AboutUs";
import ServiceTerms from "./screens/ServiceTerms";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import ReactGA from "react-ga4";
import FAQ from "./screens/FAQ";

export const URL = process.env.REACT_APP_SERVER_URL;

// const Login = React.lazy(() => import("./screens/Login"));
// const Register = React.lazy(() => import("./screens/Register"));
const LandingPage = React.lazy(() => import("./screens/LandingPage"));
const ClientPage = React.lazy(() => import("./screens/ClientPage"));
const PropertyLoadingPage = React.lazy(() =>
  import("./screens/PropertyLoadingPage")
);
const Profile = React.lazy(() => import("./screens/Profile"));
const TicketTable = React.lazy(() => import("./screens/TicketTable"));
const ContactUs = React.lazy(() => import("./screens/ContactUs"));
const CreatePropertyRequest = React.lazy(() =>
  import("./screens/CreatePropertyRequest")
);
const MyPropertyListScreen = React.lazy(() =>
  import("./screens/MyPropertyListScreen")
);
const MyListPageView = React.lazy(() => import("./screens/MyListPageView"));
const SearchMongo = React.lazy(() => import("./screens/SearchMongo"));
const PasswordReset = React.lazy(() => import("./screens/PasswordReset"));
const ForgotPassword = React.lazy(() => import("./screens/ForgotPassword"));
const AccountPage = React.lazy(() => import("./screens/AccountPage"));
const Settings = React.lazy(() => import("./screens/Settings"));
const BillingPage = React.lazy(() => import("./screens/BillingPage"));
const BillingPaymentPage = React.lazy(() =>
  import("./screens/BillingPaymentPage")
);
const BillingConfirm = React.lazy(() => import("./screens/BillingConfirm"));
const NotFound = React.lazy(() => import("./components/NotFound"));
const CommentsTable = React.lazy(() => import("./screens/CommentsTable"));
const Home = React.lazy(() => import("./screens/Home"));
const HealthStatusPage = React.lazy(() => import("./screens/HealthStatusPage"));
const PropertyListScreen = React.lazy(() =>
  import("./screens/PropertyListScreen")
);
const AgentsAddPropertyBank = React.lazy(() =>
  import("./screens/AgentsAddPropertyBank")
);

const BasicLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const AdminLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const App = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  }, []);

  return (
    // <Router className='videoDisplay' sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}
    //   style={{
    //    backgroundColor: '#ffffff',
    //     margin: 0,
    //     padding: 0,
    //     paddingRight: '0!important',
    //     paddingLeft: '0!important',
    //     overflowX: 'hidden',
    //     width: '100%',

    //   }}

    //   >
    <main
      className='videoDisplay'
      sm={12}
      xs={12}
      md={12}
      lg={12}
      xl={12}
      xxl={12}
      style={{
        // backgroundImage: `url("./screens/asset/background_image.jpeg")`,
        // backgroundImage: `url(${backGroundImage})`,
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: 'green',
        // backgroundColor: '#faf8f5',
        backgroundColor: "#ffffff",
        margin: 0,
        padding: 0,
        justifyContent: "center",
        width: "100%",
        height: "auto",
        overflowX: "hidden",
      }}
    >
      <Routes>
        <Route path='/' element={<BasicLayout />}>
          <Route
            index
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path='home'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <LandingPage />
              </React.Suspense>
            }
          />
          <Route
            path='home/search'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <LandingPage />
              </React.Suspense>
            }
          />
          <Route
            path='home/search/:keyword'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <LandingPage />
              </React.Suspense>
            }
          />
          <Route
            path='home/page/:pageNumber'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <LandingPage />
              </React.Suspense>
            }
          />
          <Route
            path='search/:keyword/page/:pageNumber'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <LandingPage />
              </React.Suspense>
            }
          />
          <Route
            path='*'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <NotFound />
              </React.Suspense>
            }
          />
          <Route
            path='password-reset'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <PasswordReset />
              </React.Suspense>
            }
          />
          <Route
            path='forgotpassword'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <ForgotPassword />
              </React.Suspense>
            }
          />
          <Route
            path='searchMongo'
            element={
              <React.Suspense fallback={<SpinnerIcon />}>
                <SearchMongo />
              </React.Suspense>
            }
          />
          <Route path='aboutUs' element={<AboutUs />} />
          <Route path='terms' element={<ServiceTerms />} />
          <Route path='privacy' element={<PrivacyPolicy />} />
          <Route path='faq' element={<FAQ />} />
        </Route>

        <Route
          path='/admin'
          element={
            <React.Suspense
              fallback={
                <>
                  <SpinnerIcon />
                </>
              }
            >
              <AdminLayout />
            </React.Suspense>
          }
        >
          <Route
            path='*'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <NotFound />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/clientPage'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <ClientPage />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/propertyLoadingPage'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <PropertyLoadingPage />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/profile'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <Profile />{" "}
              </React.Suspense>
            }
          />
          <Route
            path='/admin/AccountPage'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <AccountPage />{" "}
              </React.Suspense>
            }
          />
          <Route
            path='/admin/Settings'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <Settings />{" "}
              </React.Suspense>
            }
          />
          <Route
            path='/admin/BillingPage'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <BillingPage />{" "}
              </React.Suspense>
            }
          />
          <Route
            path='/admin/BillingPaymentPage'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <BillingPaymentPage />{" "}
              </React.Suspense>
            }
          />
          <Route
            path='/admin/BillingConfirm/upgrade'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <BillingConfirm />{" "}
              </React.Suspense>
            }
          />
          <Route
            path='/admin/contactUs'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <ContactUs />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/createPropertyRequest'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <CreatePropertyRequest />
              </React.Suspense>
            }
          />

          <Route
            path='/admin/propertyList'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <MyPropertyListScreen />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/propertyList/search'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <MyPropertyListScreen />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/propertyList/search/:keyword'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <MyPropertyListScreen />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/propertyList/page/:pageNumber'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <MyPropertyListScreen />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/propertyList/search/:keyword/page/:pageNumber'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <MyPropertyListScreen />
              </React.Suspense>
            }
          />

          <Route
            path='/admin/myListPageView'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <MyListPageView />
              </React.Suspense>
            }
          />

          <Route
            path='/admin/CommentsTable'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <CommentsTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/CommentsTable/search'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <CommentsTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/CommentsTable/search/:keyword'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <CommentsTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/CommentsTable/page/:pageNumber'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <CommentsTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/CommentsTable/search/:keyword/page/:pageNumber'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <CommentsTable />
              </React.Suspense>
            }
          />

          <Route
            path='/admin/TicketTable'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <TicketTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/TicketTable/search'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <TicketTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/TicketTable/search/:keyword'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <TicketTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/TicketTable/page/:pageNumber'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <TicketTable />
              </React.Suspense>
            }
          />
          <Route
            path='/admin/TicketTable/search/:keyword/page/:pageNumber'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <TicketTable />
              </React.Suspense>
            }
          />

          <Route
            path='/admin/healthStatus'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <HealthStatusPage />
              </React.Suspense>
            }
          />

          <Route
            path='/admin/propertyListnew'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <PropertyListScreen />
              </React.Suspense>
            }
          />

          <Route
            path='/admin/agentsAddPropertyBank'
            element={
              <React.Suspense
                fallback={
                  <>
                    <SpinnerIcon />
                  </>
                }
              >
                <AgentsAddPropertyBank />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>

      {/* </Container> */}
    </main>

    // </Router>
  );
};

export default App;
