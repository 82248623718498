import React, { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoggedInModal, setShowLoggedInModal] = useState(false);

  const openLoginModal = () => setShowLoginModal(true);
  const closeLoginModal = () => setShowLoginModal(false);

  const openLoggedInModal = () => setShowLoggedInModal(true);
  const closeLoggedInModal = () => setShowLoggedInModal(false);

  return (
    <ModalContext.Provider
      value={{
        showLoginModal,
        openLoginModal,
        closeLoginModal,
        showLoggedInModal,
        openLoggedInModal,
        closeLoggedInModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
