export const CONTENT_ADD_PROPERTY_REQUEST = "CONTENT_ADD_PROPERTY_REQUEST";
export const CONTENT_ADD_PROPERTY_SUCCESS = "CONTENT_ADD_PROPERTY_SUCCESS";
export const CONTENT_ADD_PROPERTY_FAIL = " CONTENT_ADD_PROPERTY_FAIL";
export const USER_LOGOUT = "USER_LOGOUT ";

export const CONTENT_PROPERTY_DETAILS_REQUEST =
  "CONTENT_PROPERTY_DETAILS_REQUEST";
export const CONTENT_PROPERTY_DETAILS_SUCCESS =
  "CONTENT_PROPERTY_DETAILS_SUCCESS";
export const CONTENT_PROPERTY_DETAILS_FAIL = " CONTENT_PROPERTY_DETAILS_FAIL";

export const MY_PROPERTY_LIST_REQUEST = "MY_PROPERTY_LIST_REQUEST";
export const MY_PROPERTY_LIST_SUCCESS = "MY_PROPERTY_LIST_SUCCESS";
export const MY_PROPERTY_LIST_FAIL = " MY_PROPERTY_LIST_FAIL";

export const ALL_PROPERTY_LIST_REQUEST = "ALL_PROPERTY_LIST_REQUEST";
export const ALL_PROPERTY_LIST_SUCCESS = "ALL_PROPERTY_LIST_SUCCESS";
export const ALL_PROPERTY_LIST_FAIL = " ALL_PROPERTY_LIST_FAIL";

export const PROPERTY_DETAILS_REQUEST = "PROPERTY_DETAILS_REQUEST";
export const PROPERTY_DETAILS_SUCCESS = "PROPERTY_DETAILS_SUCCESS";
export const PROPERTY_DETAILS_FAIL = " PROPERTY_DETAILS_FAIL";

export const CONTENT_DELETE_REQUEST = "CONTENT_DELETE_REQUEST";
export const CONTENT_DELETE_SUCCESS = "CONTENT_DELETE_SUCCESS";
export const CONTENT_DELETE_FAIL = "CONTENT_DELETE_FAIL";

export const CONTENT_TOPUBLIC_REQUEST = "CONTENT_TOPUBLIC_REQUEST";
export const CONTENT_TOPUBLIC_SUCCESS = "CONTENT_TOPUBLIC_SUCCESS";
export const CONTENT_TOPUBLIC_FAIL = "CONTENT_TOPUBLIC_FAIL";
export const INCREMENT_COUNT = "INCREMENT_COUNT";
