import Spinner from "react-bootstrap/Spinner";

const SpinnerIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 20,
        zIndex: 1001,
      }}
    >
      <Spinner animation='border' role='status' size='lg'>
        {/* <span className="visually-hidden">Loading...</span> */}
      </Spinner>
    </div>
  );
};

export default SpinnerIcon;
