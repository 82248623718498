import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import { Container } from "react-bootstrap";

const ServiceTerms = () => {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === "s" || e.key === "p")) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container>
      <h4 style={{ textAlign: "center", margin: "1rem" }}>Terms Of Use</h4>

      <p
        style={{
          fontSize: 16,
          margin: 10,
          marginTop: "2rem",
          marginBottom: "2rem",
          padding: 5,
        }}
      >
        <ol>
          <li>
            Who we are and how you can reach us <br />
            www.estatesands.com is a website (the “Website”) owned and operated
            by Estate Sands.
            <br />
            To reach us, please email support@estatesands.com
          </li>
          <li>
            You accept these Terms by using the Website <br />
            These Website terms of use (“Terms”) constitute a legal agreement
            between us and you. By using the Website, you confirm that you
            accept these Terms and that you agree to comply with them. If you do
            not accept and agree to comply with these Terms, then you must not
            use the Website.
            <br />
            You may not use the Website and may not accept these Terms if (a)
            you are not of eighteen (18) years of age, or (b) you are a person
            who is either barred or otherwise legally prohibited from receiving
            or using the Website under the laws of the country in which you are
            a resident or from which you access or use the Website.
            <br />
            You are responsible for ensuring that all persons who use the
            Website through your network are aware of these Terms and other
            applicable terms and conditions, and that they comply with them.
          </li>
          <li>
            These are other terms that may apply to you <br />
            These Terms incorporate the following additional terms, which also
            apply to your use of the Website: our Privacy Policy; and if you
            download our mobile app, our App Terms of Use. <br />
          </li>
          <li>
            How we process your personal information <br />
            We will only collect and process your personal information in
            compliance with our Privacy Policy. <br />
          </li>
          <li>
            How you may use material on the Website. <br />
            We are the owner or the licensee of all intellectual property rights
            in the Website and in the material published on it. Those works are
            protected by copyright laws and treaties around the world. All such
            rights are reserved. <br />
            We, together with our suppliers and licensors expressly reserve all
            intellectual property rights in all text, programs, products,
            processes, technology, content and other materials, which appear on
            the Website. Access to the Website does not confer and shall not be
            considered as conferring upon anyone any license under any of our or
            any third party's intellectual property rights. Any use of the
            Website or its contents, including copying or storing it or them in
            whole or part, other than for your own personal, non-commercial use,
            is prohibited without our permission. You may not modify, distribute
            or re-post anything on the Website for any purpose. <br />
            Our names and logos and all related product and service names,
            design marks and slogans are the trade marks or service marks of us
            or our licensors. No trade mark or service mark license is granted
            in connection with the materials contained on the Website. <br />
            Access to the Website does not authorise anyone to use any name,
            logo or mark in any manner whatsoever. <br />
            You may temporarily download copies of the materials (information or
            software) on the Website for personal, non-commercial transitory
            viewing only and you may draw the attention of others within your
            organisation to content posted on the Website. <br />
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text. <br />
            Our status (and that of any identified contributors) as the authors
            of content on the Website must always be acknowledged. You must not
            use any part of the content on the Website for commercial purposes
            without obtaining a licence to do so from us or our licensors.{" "}
            <br />
            If you print off, copy or download any part of the Website in breach
            of these Terms, your right to use the Website will cease immediately
            and you must, at our option, return or destroy any copies of the
            materials in your possession whether in electronic or printed
            format. <br />
          </li>
          <li>
            You must keep your account details safe <br />
            If you choose, or if you are provided with, a user identification
            code, password or any other piece of information as part of our
            security procedures, you must treat such information as
            confidential. You must not disclose it to any third party. <br />
            We have the right to disable any user identification, code or
            password, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion you have failed to comply with any of the
            provisions of these Terms. <br />
            If you know or suspect that anyone other than you knows your user
            identification, code or password, you must notify us at
            support@estatesands.com <br />
          </li>
          <li>
            How you must not act when using the Website <br />
            You must not do any act that we would deem to be inappropriate, is
            unlawful or is prohibited by any laws applicable to the Website,
            including, but not limited to: using any automated device, software
            process or means to access, retrieve, scrape, or index the Website
            or any content on the Website; using any device, software, process
            or means to interfere or attempt to interfere with the proper
            working on the Website; undertaking any action that will impose a
            burden or make excessive traffic demands on our infrastructure that
            we consider to be unreasonable or disproportionate Website usage;
            attempting to decipher, decompile, disassemble or reverse engineer
            any of the software comprising or in any way making up a part of the
            Website; using or indexing any content or data on the Website for
            purposes of: constructing or populating a searchable database of
            videos; building a database of video information; or competing with
            us in any manner that we have not specifically authorised;
            transmitting spam, chain letters, contents, junk email, surveys, or
            other mass messaging, whether commercial in nature or not; using the
            Website or any content from the Website in any manner which we
            determine as not reasonable and/or not for the purpose which it is
            made available; violating the rights of any person, including
            copyright, trade secret, privacy right, or any other intellectual
            property or proprietary right; posing as any person or entity or
            attempt to solicit money, passwords or personal information from any
            person; acting in violation of any such terms or other condition
            stipulated by us or any applicable law; reproducing, republishing,
            retransmitting, modifying, adapting, distributing, translating,
            creating derivative works or adaptation of, publicly displaying,
            selling, trading, or in any way exploiting the Website or any
            content on the Website, except as expressly authorised by us;
            transmitting or attempting to transmit any computer viruses, worms,
            defects or other items of a destructive manner; gaining or
            attempting to gain unauthorised access to the Website, the server on
            which the Website is stored, or any server, computer or database
            connected to the Website; or attacking or attempting to attack the
            Website via a denial-of-service attack or a distributed
            denial-of-service attack. If necessary, we may put in place measures
            to prevent unauthorised access and use of the Website, including but
            not limited to, instituting technological barriers, or reporting the
            unauthorised or illegal conduct to any person or entity.
          </li>
          <li>
            Our responsibility for loss or damage suffered by you <br />
            To the extent permitted by law, we will not be liable to you for any
            loss or damage, whether in contract, tort (including negligence),
            breach of statutory duty, or otherwise, even if foreseeable, 3
            arising out of the use or inability to use the Website; or the use
            of or reliance on any content displayed on the Website. <br />
            We will not be liable for any loss or damage arising under or in
            connection with: any failures due to software or internet errors or
            unavailability, or any other circumstances beyond our reasonable
            control; any loss of your password or account if caused by a
            breakdown, error, loss of power or otherwise cause by or to your
            computer system and/or your account; the use of, or inability to
            use, our Website; the reliance on any content or information
            displayed on our Website; any direct, consequential, special or
            punitive loss, damage, costs and expenses; loss of profit; loss of
            business; loss of reputation; depletion of goodwill; or loss of,
            damage to or corruption of data. <br />
            Unless we otherwise expressly agree in writing, you agree not to use
            the Website for any commercial or business purposes. <br />
            We do not guarantee that the Website will be secure or free from
            errors, bugs or viruses. We are not liable to you or anyone else for
            any loss or damage caused by a virus, distributed denial of service
            attack or other technological harmful material that may infect your
            computer equipment, computer programmes, data or other proprietary
            materials due to your use of the Website or to your downloading of
            any content on it, or any website linked to it. You are responsible
            for configuring your information technology, computer programmes and
            platform to access the Website. You should use your own virus
            protection software. <br />
            If you enquire about a property on this Website, you acknowledge and
            agree that your details will be sent by email or other communication
            means, such as WhatsApp, directly to the agent, estate agent,
            landlord, developer marketing the property or properties you are
            enquiring about. We do not accept any liability for any subsequent
            communications that you receive directly from that estate agent,
            landlord or developer and/or any third party.
            <br />
          </li>
          <li>
            Content on the Website <br />
            The materials appearing on the Website could include technical,
            typographical, or photographic errors. We do not warrant that any of
            the materials on the Website are accurate, complete, or current. We
            may make changes to the materials contained or displayed on the
            Website at any time without notice. <br />
          </li>
          <li>
            Making the Website available to you <br />
            We strive to ensure that the Website and the services are always
            available to you, but we do not guarantee that the Website or the
            services will operate continuously, without interruptions or be
            fault free. On occasion, necessary maintenance or upgrade work
            requires us to make the Website and the services unavailable without
            notice, but we aim to keep downtime to a minimum. We accept no
            liability for any interruption or loss of service. We may alter,
            suspend or discontinue any part of the Website or the services,
            including your access to it. <br />
          </li>
          <li>
            Links to other websites <br />
            The Website may contain links, hyperlinks and pointers to third
            party products, services and/or websites that are not affiliated
            with us and which are provided for your information only. We have no
            control over the content, products, services of those sites or
            resources of third parties and we do not guarantee or take
            responsibility for them. The Website may also contain advertising
            from third parties and we are not responsible, nor do we make any
            warranties or representations for any misleading or inaccurate
            advertisements which are the sole responsibility of the advertiser.{" "}
            <br />
            Any links or advertisements on the Website should not be interpreted
            as approval by us of those linked sites or information you may
            obtain from them. The Website also contains data provided by third
            parties and we are not responsible for, nor do we make any
            warranties or representations for any inaccuracies in such data. You
            agree to release us from any claims or disputes of any kind arising
            from or in any way connected to such disputes with third parties.{" "}
            <br />
            By using the Website, you grant us an irrevocable, world-wide,
            royalty free license to commercialise, copy, license to other
            persons, use and adapt for any purpose any material you generate or
            submit to make use of the Website. We do not warrant that the
            content, links, or sub- domains contained on, or associate with the
            Website will be available and accessible to you at all times.
            Information on our publications, posts, inserts, information,
            content should not be regarded as a substitute for professional
            legal, financial or real estate advice. <br />
          </li>
          <li>
            Changes to the Terms We may revise these Terms from time to time
            without notice. Each time you wish to use the Website, please check
            these Terms to ensure that you understand the terms that apply at
            that time. In addition, we may also update and change the Website
            from time to time without notice.
          </li>
          <li>
            Your contributions on the Website <br />
            In these Terms “Contributions” means any information including data,
            text, video, still images, audio or other material that we have
            permitted you to host, share, publish, post, store or upload on the
            Website. We may at any time, without liability to you, remove, alter
            or disable access to any or all of your Contributions in our sole
            discretion without prior notice to you. We may also remove or
            disable access to any or all of your Contributions if we consider
            that: those Contributions are in breach of any law or regulation;
            those Contributions infringe the intellectual property rights of any
            third party; we are required to do so by a regulatory body or any
            relevant authority pursuant to an interim or final take-down notice;
            those Contributions are; misleading or deceptive; inappropriate
            having regard to the purpose of the Website; likely to cause
            offence; materially incorrect; obscene; defamatory; otherwise
            unlawful and/or against the customs or norms of the region in which
            this Website is referred to; or corrupted, due to the presence of a
            virus or other disabling code. <br />
            To the extent that any Contributions are proprietary in nature, you
            grant us a worldwide, non- exclusive, royalty-free, perpetual,
            transferable and irrevocable license to use, reproduce, modify,
            adapt, translate, distribute, publish, create derivative works from
            and display and publicly perform your Contributions throughout the
            world in any medium, whether currently in existence or not. <br />
            You also grant each user of the Website the right to use your name
            or the name you submit with the Contribution, and, the right to
            represent and warrant that: you own and control all of the rights to
            the Contributions; or you have the lawful right including all
            necessary licences, rights, consents and permissions to use and
            authorise us to display the Contributions. <br />
            For any Contributions that you may retain moral rights in, you
            declare that: you do not require that any personally identifying
            information be used in connection with the Contribution, or any
            derivative work, upgrade or update of the Contribution; and You
            understand that when accessing the Website you may be exposed to the
            Contributions of other users of the Website. You acknowledge and
            agree that we do not have control of and are not responsible nor do
            we warrant the veracity of these other Contributions. <br />
            You represent and warrant that: you have the lawful right including
            all necessary licenses, rights, consents and permissions to use and
            authorise us to display your Contributions; you will not make any
            Contributions that infringe the intellectual property rights of any
            third party, and you agree to pay all royalties, fees or other
            monies payable by reason of any Contributions made by you; and you
            will not make any Contributions that are: misleading; deceptive;
            materially incorrect; likely to cause offence; directly or
            indirectly involve the advertising or marketing of any products or
            services; obscene, including pornographic, hateful, racially or
            ethnically offensive material; defamatory; otherwise unlawful or
            encourage unlawful conduct; or otherwise inappropriate having regard
            to the purpose of our Website.
          </li>
          <li>
            Communicating with you <br />
            When you use the Website or send emails to us, you are communicating
            with us electronically. You hereby consent to receive electronically
            any communications related to your use of the Website. We will
            communicate with you by email or other communication means, such as
            WhatsApp, or by posting notices on the Website. You acknowledge and
            agree that all agreements, notices, disclosures and other
            communications that are provided to you electronically satisfy any
            legal requirement that such communications be in writing. All
            notices from us intended for receipt by you shall be deemed
            delivered and effective when sent to the email address or the mobile
            number you provide on the Website for your account.
          </li>
          <li>
            Indemnity You agree to indemnify and hold us and our affiliates (and
            our officers, agents, partners and employees) against any and all
            loss, liability, claim or demand, including reasonable attorney’s
            fees, arising out of, or in connection with your use of and access
            to the Website or making Contributions not in accordance with the
            Terms.
          </li>
          <li>
            Disclaimer <br />
            The materials on the Website are provided on an "as is" and “as
            available” basis and we make no warranties, expressed or implied,
            and hereby disclaim and negate all other warranties, including
            without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of
            rights. Further, we do not warrant or make any representation
            concerning the accuracy, likely results, or reliability of the use
            of the materials on the Website or otherwise relating to such
            materials or on any site linked to the Website.
          </li>
          {/* <li>
     Governing law and jurisdiction <br />
    These Terms and any non-contractual obligations arising in connection with these Terms and your access to the Website shall be governed by and construed in accordance with the laws of the Dubai International Financial Centre (DIFC) in the Emirate of Dubai, United Arab Emirates. You agree to submit to the exclusive jurisdiction of the Courts of the Dubai International Financial Centre (DIFC).
  </li> */}
          <li>
            General terms <br />
            If any term of these Terms is or may become for any reason invalid
            or unenforceable at law, the validity and enforceability of the
            remainder will not be affected. <br />
            These Terms and the documents referred to or incorporated in them
            constitute the entire agreement between us and replaces all previous
            agreements and understandings between them, relating to its subject
            matter. <br />
          </li>
          <li>
            Contact us <br />
            To report any suspected fraudulent or misleading postings on our
            site please send us an email with the details to
            support@estatesands.com <br />
            If you have any queries, complaints or recommendations about these
            Terms of Use, please contact us at the following address:
            support@estatesands.com <br />
          </li>
        </ol>

        <ScrollToTop
          smooth
          style={{
            color: "#fff",
            float: "right",
            width: 50,
            height: 50,
            borderRadius: 50,
            position: "relative",
            alignSelf: "center",
          }}
        />
      </p>
    </Container>
  );
};

export default ServiceTerms;
